<template>
	<div>
		<h4 class="h3_title" style="text-align: center">{{ show_name }}</h4>
	
		<div style="margin: 30px auto 50px auto;width: 150px">
			<van-image :src="require('@/assets/images/deposit_maintenance.png')" width="150px" />
		</div>

		<div style="text-align: center;font-size: 18px;padding-bottom: 20px;line-height: 190%">维护中，请稍候再试</div>

		<div class="common_block" style="text-align:center">
			<van-button type="warning" round @click="nextStep()" size="small">确定</van-button>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'deposit_maintenance',
		data() {
			return {
				formItem: {
					token: "",
					currency_id: "",
					merchant_id: "",
					total: ""
				},

				show_name: "",
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')

				return false
			} 

			var deposit_info = window.sessionStorage.getItem('deposit_info')

			if (!deposit_info) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}

			deposit_info = JSON.parse(deposit_info)

			if (deposit_info['currency_id'] == undefined || !deposit_info['currency_id']) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}
			
			if (deposit_info['merchant_id'] == undefined || !deposit_info['merchant_id']) {
				this.$router.replace('/deposit/index?token=' + this.$route.query.token)

				return false
			}

			this.formItem.token = this.$route.query.token
			this.formItem.currency_id = deposit_info['currency_id']
			this.formItem.merchant_id = deposit_info['merchant_id']

			this.symbol = deposit_info['symbol']

			this.init()
		},
		methods: {
			init() {
				this.$axios({
					method: 'post',
					url: 'cash_deposit/merchant', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.show_name = res.data.show_name
					}
				})
			},
			nextStep() {
				this.$router.go(-1)
			}
		},
	}
</script>
